<template>
	<v-card dark color="secondary" elevation="0">
		<v-card-title>LOG DETAIL</v-card-title>
		<v-card-text>
			<v-data-table
				:headers="headers"
				:items="myPositions"
				item-key="timestamp"
				class="elevation-1"
				:items-per-page="10"
				:footer-props="{
					'items-per-page-options': [10, 20, 50, 100, 200]
				}"
			>
				<template v-slot:item.timestamp="{ item }">
					{{ item.timestamp  }}<br/>
					{{ item.timestamp  | moment('MM/DD/YYYY, H:mm:ss') }}
				</template>
				<template v-slot:item.date="{ item }">
					{{ item.date  }}<br/>
					{{ item.date  | moment('MM/DD/YYYY, H:mm:ss') }}
				</template>
				<template v-slot:item.action="{ item }">

					<v-icon color="white" class="mr-2" @click="showMap(item)">
						mdi-map-marker-radius
					</v-icon>
				</template>

			</v-data-table>
		</v-card-text>
		<dialog-reg-log
			:dialog="dialog"
			:item="regLog"
			:key="regLog.timestamp"
			:center="center"
			:markers="markers"
			@closeDialog="close"
		/>
	</v-card>
</template>

<script>
	import moment from 'moment';
	export default {
		name: 'ShowGmapLog',
		props: {
			positions: Array,
		},
		components: {
			DialogRegLog: () => import('./DialogRegLog.vue')
		},
		data() {
			return {
				headers: [
					{text: 'Timestamp', value: 'timestamp', align: 'left'},
					{text: 'Date', value: 'date', align: 'left'},
					{text: 'Latitude', value: 'latitude', align: 'left'},
					{text: 'Longitude', value: 'longitude', align: 'left'},
					{text: '', value: 'action', sortable: false,align: 'end', width: '12%'},
				],
				regLog: {
					date: null,
					latitude: null,
					longitude: null,
					timestamp: null,
					position: null
				},
				dialog: false,
				center: {},
				markers: [],
			}
		},
		methods: {
			showMap(item){
				this.regLog = {
					date: moment(item.date).format('MM/DD/YYYY, H:mm:ss'),
					latitude: item.latitude,
					longitude: item.longitude,
					timestamp: item.timestamp
				}
				const lat = parseFloat(item.latitude) / 10000000;
				const lon = parseFloat(item.longitude) / 10000000;
				this.center = { lat: parseFloat(item.latitude),	lng: parseFloat(item.longitude) }
				this.markers =[{
					position: {lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) },
					timestamp: item.timestamp
				}]

				// console.log(item)
				// console.log( moment(item.date).format('MM/DD/YYYY, H:mm:ss') )
				// alert('Mostrando mapa')
				this.dialog = true
			},
			close(){
				this.regLog = {
					date: null,
					latitude: null,
					longitude: null,
					timestamp: null
				}
				this.dialog = false
			},
		},
		computed: {
			myPositions(){
				return this.positions.sort((a,b) => (a.timestamp < b.timestamp) ? 1 : ((b.timestamp < a.timestamp) ? -1 : 0))

			}
		}
	}
</script>

<style lang="scss" scoped>

</style>
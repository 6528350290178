var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"dark":"","color":"secondary","elevation":"0"}},[_c('v-card-title',[_vm._v("LOG DETAIL")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.myPositions,"item-key":"timestamp","items-per-page":10,"footer-props":{
				'items-per-page-options': [10, 20, 50, 100, 200]
			}},scopedSlots:_vm._u([{key:"item.timestamp",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(item.timestamp)),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(item.timestamp,'MM/DD/YYYY, H:mm:ss'))+" ")]}},{key:"item.date",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(item.date)),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(item.date,'MM/DD/YYYY, H:mm:ss'))+" ")]}},{key:"item.action",fn:function(ref){
			var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"},on:{"click":function($event){return _vm.showMap(item)}}},[_vm._v(" mdi-map-marker-radius ")])]}}])})],1),_c('dialog-reg-log',{key:_vm.regLog.timestamp,attrs:{"dialog":_vm.dialog,"item":_vm.regLog,"center":_vm.center,"markers":_vm.markers},on:{"closeDialog":_vm.close}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }